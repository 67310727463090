<template>
  <b-container class="c-page">
    <div class="c-page__title">
      <div class="d-flex">
        <h1 class="d-flex align-items-center">
          {{ meetingData.name }}&nbsp;
          <span v-if="meetingData.date != undefined" variant="primary">{{
              meetingData.date.date | moment("DD/MM/YYYY")
            }}</span>

        </h1>
        <b-button class="float-left align-self-start ml-2" size="sm" @click="exportRanking()" variant="primary">
          <b-icon aria-hidden="true" icon="download"></b-icon>&nbsp;
          {{ $t('meetings.exportRankingBtn') }}
        </b-button>
        <b-button class="float-left align-self-start ml-2" size="sm" @click="exportDatabank()" variant="primary">
          <b-icon aria-hidden="true" icon="download"></b-icon>&nbsp;
          {{ $t('meetings.exportDatabank') }}
        </b-button>
      </div>
      <div class="c-page__controls mb-2">
        <b-button class="float-left align-self-start" size="" @click="goBack" variant="primary">
          <b-icon aria-hidden="true" icon="arrow-left-circle-fill"></b-icon>&nbsp;
          {{ $t('universalTxt.goBack') }}
        </b-button>

      </div>
    </div>

    <div class="row">
      <div class="c-page__table-scrollable-wrap col-12">
        <table class="table c-page__table">
          <thead>
          <tr>
            <th class="c-page__table-control-col">#</th>
            <th>{{ $t('projects.tableColDate') }}</th>
            <th>
                              <span class="d-flex align-items-center">
                                  {{ $t('projects.tableColRegN') }}
                              </span>
            </th>
            <th>
                              <span class="d-flex align-items-center">
                                 {{ $t('projects.tableColUserName') }}
                              </span>
            </th>
            <th>
                              <span class="d-flex align-items-center">
                                  {{ $t('projects.tableColName') }}
                              </span>
            </th>
            <th colspan="2">
              {{ $t('statesShortcut.state') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in projectData">
            <tr :key="item.id">
              <td class="c-page__table-control-col">
                <b-icon v-if="item.visible" icon="eye-fill" variant="primary"></b-icon>
                <b-icon v-else icon="eye-slash" variant="primary"></b-icon>
              </td>
              <td>
                                <span v-if="item.public_date !== null && item.public_date !== undefined">
                                {{ item.public_date.date | moment("DD/MM/YYYY HH:mm:ss") }}
                                </span>
              </td>
              <td class="text-center">
                {{ item.registration_number }}
              </td>
              <td>
                <div class="text-clamp-3">{{ item.user_name }}</div>
              </td>
              <td>
                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                  {{ item['name_' + $i18n.locale] }}
                </router-link>
              </td>
              <td>
                                <span v-if="item.status!=''">
                                    {{ $t('statesBtn.' + item.status) }}
                                </span>
              </td>
              <td class="text-right c-page__table-edit">
                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                  <b-icon class="clickable" icon="pencil-square" variant="primary"></b-icon>
                </router-link>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>


    <router-view @loadData="loadData()"></router-view>
  </b-container>

</template>

<script>

export default {
  name: 'meeting',
  components: {},
  data() {
    return {
      id: parseInt(this.$route.params.id),
      projectData: [],
      meetingData: [],
    }
  },
  methods: {
    async loadProjectData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/meetings/projects-list.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {'id': this.id},
          method: 'GET'
        });

        this.projectData = response.data;

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'no data');
      }

    },
    async loadMeetingData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/meetings/list.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {'id': this.id},
          method: 'GET'
        });

        this.meetingData = response.data[0];

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'no data');
      }

    },

    async exportDatabank() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/export-xls.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {id: this.id},
          method: 'GET'
        });

        const link = document.createElement('a');

        console.log(response.data.data);
        link.href = process.env.VUE_APP_SERVER + response.data.data;
        link.download = '';
        link.target = '__blank';
        link.click();


      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }
    },
    async exportRanking() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/articles/export-ranking.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          data: {id: this.id},
          method: 'POST'
        });

        const link = document.createElement('a');
        link.href = process.env.VUE_APP_SERVER + response.data.data;
        link.download = '';
        link.target = '__blank';
        link.click();


      } catch (error) {
        await this.loadData();
        console.log("error", error);
      }
    },
    goBack() {
      this.$router.back();
    }
  },
  computed: {},
  mounted() {
    this.loadProjectData();
    this.loadMeetingData();
  }
}
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 500;
}
</style>
